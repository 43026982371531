<template>
  <div>
    <Header />

    <div class="contact-us">
      <!-- <b-container> -->
      <div class="contact">
        <div class="contact-top breadcrumbs d-flex align-items-center">
          <div
            class="container position-relative d-flex flex-column align-items-center"
          >
            <!-- <h3>{{ $t("contact.Contact_Us") }}</h3> -->
          </div>
        </div>
        <!-- <img src="../../../assets/images/info/about-us.jpg" alt="" /> -->
      </div>
      <b-container>
        <b-row>
          <b-col sm="12" md="12">
            <div class="banner-box1">
              <div class="container text-center">
                <h1 class="contact-title h1">{{ $t("contact.Contact_Us") }}</h1>
                <p>{{ $t("contact.Contact_Us_main") }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="contact-info">
          <b-col sm="3">
            <div class="contact-info-block">
              <!-- <i class="fa-solid fa-phone" /> -->
              <img
                src="../../../assets/images/info/contact/contact_customer_support.png"
                alt=""
                width="100px"
              />

              <div class="contact-data">
                <h3>{{ $t("contact.Contact_s1_heading") }}</h3>
                <p>
                  {{ $t("contact.Contact_s1_p1") }} <br />
                  <a :href="'mailto:' + $t('contact.Contact_s1_mail')">{{
                    $t("contact.Contact_s1_mail")
                  }}</a>
                </p>
              </div>
            </div>
          </b-col>
          <b-col sm="3">
            <div class="contact-info-block">
              <!-- <i class="fa-solid fa-phone" /> -->
              <img
                src="../../../assets/images/info/contact/contact_sales.png"
                alt=""
                width="100px"
              />

              <div class="contact-data">
                <h3>{{ $t("contact.Contact_s2_heading") }}</h3>
                <p>
                  {{ $t("contact.Contact_s2_p1") }} <br />
                  <a :href="'mailto:' + $t('contact.Contact_s2_mail')">{{
                    $t("contact.Contact_s2_mail")
                  }}</a>
                </p>
              </div>
            </div>
          </b-col>
          <b-col sm="3">
            <div class="contact-info-block">
              <!-- <i class="fa-solid fa-phone" /> -->
              <img
                src="../../../assets/images/info/contact/contact_media.png"
                alt=""
                width="100px"
              />

              <div class="contact-data">
                <h3>{{ $t("contact.Contact_s3_heading") }}</h3>
                <p>
                  {{ $t("contact.Contact_s3_p1") }} <br />
                  <a :href="'mailto:' + $t('contact.Contact_s3_mail')">{{
                    $t("contact.Contact_s3_mail")
                  }}</a>
                </p>
              </div>
            </div>
          </b-col>
          <b-col sm="3">
            <div class="contact-info-block">
              <!-- <i class="fa-solid fa-phone" /> -->
              <img
                src="../../../assets/images/info/contact/contact_location.png"
                alt=""
                width="100px"
              />

              <div class="contact-data">
                <h3>{{ $t("contact.Contact_s4_heading") }}</h3>
                <p>
                  {{ $t("contact.Contact_s4_p1") }}<br />{{
                    $t("contact.Contact_s4_p2")
                  }}<br />{{ $t("contact.Contact_s4_p3") }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="contact-form mb-5">
          <b-col sm="12" md="12">
            <p>
              {{ $t("contact.Contact_Us_p2") }}<br />{{
                $t("contact.Contact_Us_p2_1")
              }}
            </p>
            <p>{{ $t("contact.Contact_Us_p3") }}</p>
            <p>{{ $t("contact.Contact_Us_p4") }}</p>
            <p>{{ $t("contact.Contact_Us_p5") }}</p>
            <p>{{ $t("contact.Contact_Us_p6") }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BLink,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

Vue.use(VueReCaptcha, {
  siteKey: "6LeGZhMiAAAAABMuHHTgeSUQM-wRmrsDAFnxPR_4",
  loaderOptions: {
    useRecaptchaNet: true,
  },
});
export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BContainer,
    BForm,
    BRow,
    BCol,
    ToastificationContent,
    BButton,
    BFormTextarea,
    BLink,
  },
  data() {
    return {
      subject: "",
      name: "",
      email: "",
      message: "",
    };
  },
  beforeDestroy() {
    const recaptcha = this.$recaptchaInstance;
    recaptcha.hideBadge();
  },

  methods: {
    ...mapActions({
      contactUs: "profile/contactUs",
    }),
    async contactUsMethod() {
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      await this.$recaptcha("login")
        .then((response) => {
          console.log(response);
          this.$refs.loginForm.validate().then((success) => {
            if (success) {
              const userData = new FormData();
              userData.append("name", this.name);
              userData.append("email", this.email);
              userData.append("subject", this.subject);
              userData.append("message", this.message);
              userData.append("recaptch", response);

              this.contactUs(userData)
                .then(() => {
                  this.$refs.loginFormValidation.reset();
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `${this.$t("contactUs.successTitle")}`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `${this.$t("contactUs.successText")}`,
                    },
                  });
                })
                .catch(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: this.$t("contactUs.errorTitle"),
                      icon: "CoffeeIcon",
                      variant: "danger",
                      text: this.$t("contactUs.errorText"),
                    },
                  });
                });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.contact-us .contact-top {
  height: 400px;
}

.contact-title {
  font-size: 40px;
  font-weight: 700;
  color: #366ce3;
  margin-bottom: 30px;
}
.contact-us .contact-form {
  font-size: large;
  margin-top: 50px;
}
.contact-us .contact-form .card {
  background-color: #f8f8f8;
  border-radius: 1.428rem;
}
.contact-form img {
  width: 450px;
  margin-top: 51px;
  margin-bottom: 40px;
  margin-left: 10%;
}
@media (max-width: 640px) {
  .contact-form img {
    display: none;
  }
}
.card-message h5 {
  color: #000;
  font-weight: 700;
  font-size: 19px;
}
/*  --------------------------------------------  */

.contact-us .contact-us .contact-top {
  width: -webkit-fill-available;
}
.contact-us .contactus-heading {
  /* border-bottom: 1px solid #cbcbcb; */
  font-size: 44px;
  line-height: 60px;
  margin: 29px 0;
  padding-bottom: 15px;
}

.contact-us .contact-heading {
  font-size: 30px;
}
.contact-us .contact-text {
  margin: 15px 0;
  font-size: 15px;
  font-weight: 700;
}
.contact-us .contact-card {
  padding: 15px 0 25px 0;
}
.contact-us .media-heading {
  font-weight: 600;
  font-size: 20px;
}

.contact-data {
  margin-top: 20px;
  padding-left: 10px;
}
.contact-data h3 {
  color: #366ce3;
  margin-bottom: 10px;
}
.contact-data p {
  font-size: 13px;
  text-align: justify;
}
.contact-us .media-body .card-text {
  font-size: 15px;
  font-weight: 500;
}
.media-aside {
  margin-top: 1rem;
}
.contact-us .socialIcon {
  text-align: center;
}
.contact-us .socialIcon a {
  display: contents;
}

.contact-us .socialIcon .fa-brands {
  font-size: 40px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.contact-us .social-heading {
  text-align: center;
  font-size: 23px;
  margin-bottom: 20px;
  font-weight: 600;
}

.contact-us .card-message {
  /* padding-bottom: 53px; */
  padding: 40px;
}
.card-message label {
  font-size: 16px;
  font-weight: 500;
}
.contact-us .heading-message {
  margin-top: 15px;
  font-size: 30px;
}
.contact-us .card-message form {
  margin-top: 20px;
}

.contact-us .card-message form .btn-primary {
  border-color: #366ce3 !important;
  background-color: #366ce3 !important;
}
</style>
<style scoped>
@media (min-width: 750px) {
  .banner-box1 {
    margin-top: 90px;
  }
}
.banner-box1 h1 {
  font-size: 40px;
  color: #2f2d7e;
}
.banner-box1 p {
  font-size: 17px;
  /* color: #000; */
}
@media (max-width: 650px) {
  .banner-box1 {
    text-align: center;
    margin-top: 20px;
  }
}
@media (min-width: 850px) {
  .next-img-box img {
    width: 90%;
    height: 500px;
    margin-top: 8%;
  }
}
@media (max-width: 550px) {
  .next-img-box img {
    width: 100%;
    height: auto;
    height: 350px;
  }
}
.global-banner-template .brush-area {
  margin-top: -2.5rem;
  margin-bottom: -2rem;
}
[class*=" icon-"],
[class^="icon-"] {
  font-family: icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: -2.5rem;
  font-size: 6rem;
  line-height: 1;
}
.icon-brush-6:before {
  content: "";
}
.brush-area {
  margin-top: -2.5rem;
}

.contact-info {
  margin-top: 80px;
  margin-bottom: 50px;
  text-align: center;
}
.contact-us .fa-location-dot {
  font-size: 35px;
  color: #366ce3;
}
.contact-us .fa-location-dot:hover {
  color: #166fb6;
}
.contact-us .fa-phone {
  font-size: 35px;
  color: #366ce3;
}
.contact-us .fa-phone:hover {
  color: #166fb6;
}
.contact-us .fa-envelope {
  font-size: 35px;
  color: #366ce3;
}
.contact-us .fa-envelope:hover {
  color: #166fb6;
}
.contact-info-block {
  margin-bottom: 18px;
}
.contact-info-block i {
  margin-bottom: 8px;
}
.contact-info-block h3 {
  font-size: 18px;
  font-weight: 500;
  /* color: #000; */
}
.contact-info-block p {
  font-size: 17px;
}

.contact-social h2 {
  color: #2f2d7e;
  text-align: center;
  font-weight: 700;
}

.contact-social ul {
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: center;
  list-style: none;
  margin-left: -36px;
}
.contact-social ul li {
  margin: 12px;
}
.contact-social ul li .fa-brands {
  font-size: 40px;
  color: #366ce3;
}
.contact-social ul li .fa-brands:hover {
  color: #166fb6;
}
.card-message {
  /* background-image: url("../../../assets/images/info/aaa.jpg"); */
  background-repeat: no-repeat;
  background-position: inherit;
  background-size: cover;
}
.info-call,
.info-email {
  border-right: 2px solid #e0e0e0;
}
.contact-social {
  margin-top: 25px;
}
</style>
<style scoped>
.contact-top {
  background-image: url(../../../assets/images/info/contact-us-header.png);
  padding: 140px 0 60px 0;
  min-height: 60vh;
  position: relative;
  background-size: cover;
  background-position: inherit;
  background-repeat: no-repeat;
}
.breadcrumbs:before {
  content: "";
  background-color: rgb(1 4 8 / 37%);
  position: absolute;
  inset: 0;
}
.breadcrumbs h3 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.fa-solid {
  background-color: #fff;
  padding: 14px;
  border-radius: 10px;
  cursor: pointer;
}
@media (max-width: 650px) {
  .info-call,
  .info-email {
    border-right: 0px solid #e0e0e0;
    border-bottom: 2px solid #e0e0e0;
  }
}
</style>
<style>
div.Vue-Toastification__container.top-right {
  z-index: 99999;
}
.banner-desc-area {
  /* background-image: url(../../../assets/images/info/contactUs-1.png); */
}
</style>
